<template>
  <v-row>
    <v-col>
      <UsersTableSection
        role="Student"
        @deposit="addDeposit"
        @wallet="seeWallet"
        @contract="seeContractBalance"
      >
        <template #exportDeposit></template>
      </UsersTableSection>
    </v-col>
    <v-col cols="12">
      <DepositTable ref="deposit" @edit="edit" />
    </v-col>
    <v-col cols="12">
      <AcademicConsaltantProfit />
    </v-col>
    <AddDeposit
      :is-show-modal="addDepositModal"
      :user="user"
      :deposit="deposit"
      @close="close"
      @update="update"
    />
    <Balances
      :is-show-modal="balancesModal"
      :balance="balance"
      @close="close"
    />
    <Contracts
      @close="contractModal = false"
      :contracts="contracts"
      :is-show-modal="contractModal"
    />
  </v-row>
</template>

<script>
import DepositTable from "@/packages/finance/components/deposit/DepositTable";
import UsersTableSection from "@/packages/finance/components/UsersTableSection";
import AddDeposit from "@/packages/finance/components/deposit/AddDeposit";
import message from "@/tools/Message";
import Balances from "@/packages/finance/components/deposit/Balances";
import AcademicConsaltantProfit from "@/packages/finance/components/deposit/AcademicConsaltantProfit";
import Contracts from "@/packages/finance/components/deposit/Contracts";
export default {
  name: "deposit",
  components: {
    Contracts,
    AcademicConsaltantProfit,
    Balances,
    AddDeposit,
    UsersTableSection,
    DepositTable,
  },
  data() {
    return {
      addDepositModal: false,
      balancesModal: false,
      contractModal: false,
      user: null,
      deposit: null,
      balance: null,
      contracts: [],
    };
  },
  methods: {
    async addDeposit(item) {
      this.user = item;
      await this.$actions.getClassroomsOfUser({
        publicKey: item.publicKey,
        dispatch: "class/setClassroomsOfUser",
      });
      await this.$actions.getContracts({
        all: true,
        publicKey: item.publicKey,
      });
      this.addDepositModal = true;
    },
    async seeWallet(item) {
      console.log(item);
      const res = await this.$actions.allStudentsBalance({
        dispatch: "deposit/setBalances",
        publicKey: item.publicKey,
      });
      if (!res.balances?.length)
        return message.error("Student has to balances");

      this.balance = res;
      this.balancesModal = true;
    },
    async seeContractBalance(item) {
      this.contracts = await this.$actions.getStudentsContractBalance({
        studentPublicKey: item.publicKey,
      });
      this.contractModal = true;
    },
    close() {
      this.user = null;
      this.deposit = null;
      this.addDepositModal = false;
      this.balancesModal = false;
    },
    update() {
      this.$refs.deposit.init({});
    },
    async edit(item) {
      this.deposit = item;
      await this.$actions.getClassroomsOfUser({
        publicKey: item.publicKey,
        dispatch: "class/setClassroomsOfUser",
      });
      await this.$actions.getContracts({
        all: true,
        publicKey: item.publicKey,
      });
      this.addDepositModal = true;
    },
  },
};
</script>

<style scoped></style>
