var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700px","transition":"dialog-bottom-transition"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{staticClass:"px-4"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"color":"transparent"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.deposit ? _vm.$_t("attribute.editDeposit") : _vm.$_t("attribute.addDeposit")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('MainSoftForm',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.classes,"dense":"","disabled":_vm.disabled,"item-value":(item) => ({
                classroomName: item.classroomName,
                classroomId: item.classroomId,
                classroomLevel: item.classroomLevel,
                classroomType: item.classroomType,
              }),"item-text":(item) =>
                `${item.classroomName} ${
                  item.state === 'Archived'
                    ? '| Archived'
                    : item.state === 'Deleted'
                    ? '| Deleted'
                    : ''
                } | ${_vm.$_t(`attribute.${item.classroomLevel}`)} | ${
                  item.teacherName
                }`,"outlined":"","label":_vm.$_t('attribute.classes')},on:{"input":function($event){return _vm.bindClass($event)}},model:{value:(_vm.classroom),callback:function ($$v) {_vm.classroom=$$v},expression:"classroom"}})],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.allContracts,"dense":"","disabled":_vm.contractDisabled,"item-value":"refID","item-text":(item) =>
                `${item.refID} | ${_vm.$_t(`attribute.${item.contrcatType}`)} | ${
                  item.total
                }`,"outlined":"","label":_vm.$_t('attribute.contracts')},model:{value:(_vm.contract),callback:function ($$v) {_vm.contract=$$v},expression:"contract"}})],1),_c('MyFormGenerator',{attrs:{"lg":"10","schema":_vm.schema,"validator":_vm.$v,"enter":_vm.submit}}),(_vm.guide)?_c('v-row',{staticClass:"py-4 px-4 justify-space-between"},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(`${_vm.$_t("attribute.costPerHour")}: ${_vm.guide.costPerHour ?? 0}`)+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(`${_vm.$_t("attribute.shouldCharge")}: ${_vm.guide.shouldCharge ?? 0} H`)+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(`${_vm.$_t("attribute.shouldPay")}: ${_vm.guide.shouldPay ?? 0}`)+" ")])],1):_vm._e()]},proxy:true},{key:"action",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$_t("attribute.cancel"))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","loading":_vm.is_loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.deposit ? _vm.$_t("attribute.edit") : _vm.$_t("attribute.add"))+" ")])],1)])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }