<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('attribute.deposits')" />
      </v-col>
      <v-row class="justify-end align-center">
        <v-col cols="12" md="4">
          <v-select
            hide-details
            outlined
            :label="$_t('attribute.depositType')"
            dense
            background-color="#fff"
            :items="depositTypes"
            v-model="depositType"
            @change="init({})"
            clearable
            item-text="text"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            hide-details
            outlined
            :label="$_t('attribute.isConfirmed')"
            dense
            background-color="#fff"
            :items="[
              { text: $_t('attribute.confirmed'), value: true },
              { text: $_t('attribute.notConfirmed'), value: false },
            ]"
            v-model="isConfirmed"
            @change="init({})"
            clearable
            item-text="text"
            item-value="value"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            init({ search: $event });
          "
          :headers="headers"
          :items="deposits"
          :page-info="pageInfo"
          :loading="is_loading"
        >
          <template v-slot:top-right>
            <div class="d-flex flex-fill align-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary">{{
                    $_t("attribute.export")
                  }}</v-btn>
                </template>
                <v-list>
                  <v-list-item link>
                    <DatePicketMonth @onOk="exportAllDeposits">
                      <template #activator>
                        <v-list-item-title>{{
                          $_t("attribute.classroom")
                        }}</v-list-item-title>
                      </template>
                    </DatePicketMonth>
                  </v-list-item>
                  <v-list-item link>
                    <DatePicketMonth @onOk="exportAllContracts">
                      <template #activator>
                        <v-list-item-title>{{
                          $_t("attribute.contracts")
                        }}</v-list-item-title>
                      </template>
                    </DatePicketMonth>
                  </v-list-item>
                  <v-list-item link>
                    <DatePicketMonth @onOk="exportSideCosts">
                      <template #activator>
                        <v-list-item-title>{{
                          $_t("attribute.sideCosts")
                        }}</v-list-item-title>
                      </template>
                    </DatePicketMonth>
                  </v-list-item>
                </v-list>
              </v-menu>
              <DebtorStudentsModal>
                <template #activator>
                  <v-btn color="primary" class="ml-2">
                    {{ $_t("attribute.debtorStudents") }}
                  </v-btn>
                </template>
              </DebtorStudentsModal>
              <v-spacer />
              <v-chip class="ma-2" color="primary" label outlined>
                {{ `${$_t(`attribute.sum`)}: ${sum}` }}
              </v-chip>
            </div>
          </template>
          <template v-slot:submitMoment="{ item }">
            {{ $_date(item.submitMoment, "long") }}
          </template>
          <template v-slot:level="{ item }">
            {{ $_t(`attribute.${item.courseLevel}`) }}
          </template>
          <template v-slot:baseType="{ item }">
            {{ $_t(`attribute.${item.courseName}`) }}
          </template>
          <template v-slot:createMoment="{ item }">
            {{ $_date(item.createMoment, "long") }}
          </template>
          <template v-slot:confirmationDate="{ item }">
            {{
              item.confirmationDate ? $_date(item.confirmationDate, "long") : ""
            }}
          </template>
          <template v-slot:depositType="{ item }">
            {{ $_t(`attribute.${item.depositType}`) }}
          </template>
          <template v-slot:paymentMethod="{ item }">
            {{
              item.paymentMethod ? $_t(`attribute.${item.paymentMethod}`) : ""
            }}
          </template>
          <template v-slot:type="{ item }">
            {{ item.classroomType }}
          </template>
          <template v-slot:edit="{ item }">
            <v-btn
              icon
              color="primary"
              :disabled="
                item.confirmed && !['Admin', 'FinancialAdmin'].includes(role)
              "
              @click="editDeposit(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:delete="{ item }">
            <v-btn @click="openConfirmDelete(item)" icon color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:confirm="{ item }">
            <v-btn icon @click="openConfirmModal(item)">
              <v-icon
                color="green"
                v-if="
                  item.confirmationDetail.confirmed &&
                  item.confirmationDetail.mainConfirmed
                "
                >mdi-check</v-icon
              >
              <v-icon color="red" v-else>mdi-close</v-icon>
            </v-btn>
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="init({})"
            ></v-pagination>
          </template>
        </MainTable>
        <ConfirmDelete ref="confirmDelete" @confirm="deleteDeposit" />
        <ConfirmDeposit
          :deposit="deposit"
          :confirmationDetail="confirmationDetail"
          @confirm="confirmDeposit"
          ref="confirmDepositModal"
        >
        </ConfirmDeposit>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { DEPOSIT_TABLE_HEADER } from "@/packages/finance/schema/table/DEPOSIT_HEADER.schema";
import urls from "@/tools/Urls";
import api from "@/tools/Api";
import FileDownload from "js-file-download";
import { depositTypes } from "@/packages/finance/schema/DEPOSIT_TYPES.schema";
import ConfirmDelete from "@/components/app/ConfirmDelete";
import ConfirmDeposit from "@/packages/finance/components/deposit/ConfirmDeposit";
import message from "@/tools/Message";
import DebtorStudentsModal from "@/packages/finance/components/deposit/DebtorStudentsModal";
import DatePicketMonth from "@/packages/finance/components/DatePicketMonth";

export default {
  name: "DepositTable",
  components: {
    DatePicketMonth,
    DebtorStudentsModal,
    ConfirmDeposit,
    ConfirmDelete,
    MainTable,
    TopTitle,
  },
  computed: {
    ...mapGetters({
      deposits: "deposit/deposits",
      is_loading: "loading/is_loading",
      role: "authentication/role",
    }),
  },
  data() {
    return {
      search: "",
      addDepositModal: false,
      sum: 0,
      urls,
      depositTypes,
      depositType: null,
      isConfirmed: null,
      deposit: {},
      confirmationDetail: {
        mainConfirmed: false,
        confirmed: false,
        confirmationMessage: "",
        confirmedBy: null,
      },
    };
  },
  setup() {
    const lessonType = ref(null);
    const baseType = ref(null);
    const headers = DEPOSIT_TABLE_HEADER.headers;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    });
    return {
      lessonType,
      baseType,
      headers,
      pageInfo,
    };
  },
  methods: {
    async init({ search = this.search }) {
      this.search = search;
      const { pageCount } = await this.$actions.getDeposits({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        search: this.search,
        depositType: this.depositType,
        dispatch: "deposit/setDeposits",
        role: this.role,
        isConfirmed: this.isConfirmed,
      });
      this.pageInfo.pageCount = pageCount;
      // await this.$actions.allStudentsCharges(); //student
      this.sum = await this.$actions.getSumOfDeposits({
        depositType: this.depositType,
      });
      // await this.$actions.studentCharges(); // login user
    },
    editDeposit(item) {
      this.$emit("edit", item);
    },
    async deleteDeposit() {
      await this.$actions.deleteDeposit({
        depositId: this.deposit.depositId,
      });
      await this.init({});
    },
    confirmDeposit() {
      this.init({});
    },
    openConfirmModal(item) {
      console.log(item);
      this.deposit = {
        ...item,
      };
      this.confirmationDetail = { ...item.confirmationDetail };
      this.$refs.confirmDepositModal.open();
    },
    close() {
      this.addDepositModal = false;
    },
    openConfirmDelete(item) {
      if (this.role === "Admin") {
        this.deposit = item;
        this.$refs.confirmDelete.open();
      } else {
        if (
          item.amount === 0 &&
          (item.confirmationDetail.confirmed ||
            item.confirmationDetail.mainConfirmed)
        )
          return message.error(this.$_t("ACCESS_DENIED"));
        else {
          this.deposit = item;
          this.$refs.confirmDelete.open();
        }
      }
    },
    async exportAllDeposits({ month, year }) {
      await api
        .get(urls.qs("exportDeposits", { filter: this.search, month, year }), {
          responseType: "blob",
        })
        .then((res) => {
          FileDownload(res, `${year}-${month}-DepositReports.xlsx`);
        });
    },
    async exportAllContracts({ month, year }) {
      await api
        .get(urls.qs("exportContracts", { filter: this.search, month, year }), {
          responseType: "blob",
        })
        .then((res) => {
          FileDownload(res, `${year}-${month}-ContractsReports.xlsx`);
        });
    },
    async exportSideCosts({ month, year }) {
      await api
        .get(urls.qs("exportSideCosts", { filter: this.search, month, year }), {
          responseType: "blob",
        })
        .then((res) => {
          FileDownload(res, `${year}-${month}-SideCostReports.xlsx`);
        });
    },
  },
  created() {
    this.init({});
  },
};
</script>

<style scoped></style>
