import { _t } from "@/tools/Utils";

export const paymentTypes = [
  { text: _t("attribute.BankCardTransfer"), value: "BankCardTransfer" },
  { text: _t("attribute.POS"), value: "POS" },
  { text: _t("attribute.Cash"), value: "Cash" },
  { text: _t("attribute.Alipay"), value: "Alipay" },
  { text: _t("attribute.Wechat"), value: "Wechat" },
  { text: _t("attribute.Other"), value: "Other" },
];
