<template>
  <v-dialog v-model="modal" max-width="1100">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card :loading="isLoading">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.debtorStudents") }}
          </span>
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row v-if="students.length && !isLoading">
            <v-col
              v-for="(student, index) in students"
              :key="index"
              cols="12"
              md="6"
            >
              <v-card hover height="100%">
                <v-card-title v-for="(val, key, i) in student" :key="i">
                  {{ `${$_t(`attribute.${key}`)}: ${val}` }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else-if="!students.length && !isLoading">
            <v-col cols="12">
              <h2 class="font-weight-bold">
                {{ $_t("NO_RESULT") }}
              </h2>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> {{ $_t("attribute.close") }} </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DebtorStudentsModal",
  data() {
    return {
      modal: false,
      students: [],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/is_loading",
    }),
  },
  watch: {
    async modal(val) {
      if (val) {
        this.students = await this.$actions.getDebtorStudents();
      }
    },
  },
  methods: {
    close() {
      this.modal = false;
    },
  },
};
</script>

<style scoped></style>
