import { _t } from "@/tools/Utils";

export const DEPOSIT_TABLE_HEADER = {
  headers: [
    {
      text: _t("attribute.depositType"),
      align: "start",
      sortable: false,
      value: "depositType",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.id"),
      align: "start",
      sortable: false,
      value: "contractRefID",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.fullName"),
      align: "start",
      sortable: false,
      value: "studentFullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.baseType"),
      align: "start",
      sortable: false,
      value: "baseType",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.teacherName"),
      align: "start",
      sortable: false,
      value: "teacherFullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.classroomName"),
      align: "start",
      sortable: false,
      value: "classroomName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.classroomType"),
      align: "start",
      sortable: false,
      value: "type",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.classroomLevel"),
      align: "start",
      sortable: false,
      value: "level",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.paymentMethod"),
      align: "start",
      sortable: false,
      value: "paymentMethod",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.amount"),
      sortable: false,
      value: "amount",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.createMoment"),
      sortable: false,
      value: "createMoment",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.incomeInHour"),
      sortable: false,
      value: "incomeInHour",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.charge"),
      sortable: false,
      value: "charge",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.chargeAt"),
      sortable: false,
      value: "submitMoment",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.edit"),
      sortable: false,
      value: "edit",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.delete"),
      sortable: false,
      value: "delete",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.confirmationDate"),
      sortable: false,
      value: "confirmationDate",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.confirm"),
      sortable: false,
      value: "confirm",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
};
