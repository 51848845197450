<template>
  <v-card>
    <v-card-title class="pl-8">
      {{ $_t("attribute.ACProfit") }}
    </v-card-title>
    <v-card-text>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12" class="py-0">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.show") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card-text>
    <v-dialog v-model="ACModal" width="800">
      <v-card v-if="ACReports.length">
        <v-card-title class="text-h5 grey lighten-2">
          {{ $_t("attribute.profit") }}
        </v-card-title>
        <v-card-title>{{ $_t("attribute.reports") + " :" }}</v-card-title>
        <v-card-title class="pb-4" v-for="(s, index) in ACReports" :key="index">
          {{ `${$_t("attribute.fullName")}: ${s.academicConsultantFullName}` }}
          <br />
          {{ `${$_t("attribute.fromTime")}: ${$_date(s.from * 1000, "long")}` }}
          <br />
          {{ `${$_t("attribute.toTime")}: ${$_date(s.to * 1000, "long")}` }}
          <br />
          {{ `${$_t("attribute.profit")}: ${s.profit}` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="ACModal = false">
            {{ $_t("attribute.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { ACADEMIC_PROFIT } from "@/packages/finance/schema/ACADEMIC_PROFIT";
import { mapGetters } from "vuex";
export default {
  name: "AcademicConsaltantProfit",
  components: { MyFormGenerator, MainSoftForm },
  data() {
    return {
      schema: ACADEMIC_PROFIT.schema,
      form: ACADEMIC_PROFIT.model,
      ACReports: [],
      ACModal: false,
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      role: "authentication/role",
      user: "authentication/user",
    }),
  },
  validations: {
    ...ACADEMIC_PROFIT.validations,
  },
  methods: {
    async submit() {
      console.log(this.form);
      const res = await this.$actions.getACProfit({
        academicConsultantPublicKey:
          this.role === "AcademicConsultant"
            ? this.user.publicKey
            : this.form.academicConsultantPublicKey,
        from: new Date(this.form.fromTo[0]).getTime() / 1000 || null,
        to: new Date(this.form.fromTo[1]).getTime() / 1000 || null,
        ignoreRoleFilter: ["FinancialAdmin"].includes(this.role),
      });
      console.log(res);
      if (!res) return;
      this.ACReports = [];
      this.ACReports.push(res);
      this.ACModal = true;
    },
  },
  async mounted() {
    if (this.role === "AcademicConsultant") {
      this.schema[
        this.getIdWithName(this.schema, "academicConsultantPublicKey")
      ].disabled = true;
      return;
    }
    const { users } = await this.$actions.getUsers({
      all: true,
      role: "AcademicConsultant",
    });
    this.schema[
      this.getIdWithName(this.schema, "academicConsultantPublicKey")
    ].items = users;
  },
};
</script>

<style scoped></style>
