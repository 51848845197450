export const ACADEMIC_PROFIT = {
  /* Form SCHEMA */
  schema: [
    {
      id: "academicConsultantPublicKey",
      label: "academicConsultantPublicKey",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      text: "fullName",
      clearable: true,
      value: "publicKey",
      show: true,
      items: [],
    },
    {
      id: "fromTo",
      label: "fromTo",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "date",
      range: true,
    },
  ],

  /* Form MODEL */
  model: {
    academicConsultantPublicKey: null,
    fromTo: [],
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      academicConsultantPublicKey: {},
      fromTo: {},
    },
  },
};
