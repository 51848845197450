<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          deposit ? $_t("attribute.editDeposit") : $_t("attribute.addDeposit")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <v-col cols="12">
            <v-select
              hide-details
              :items="classes"
              dense
              :disabled="disabled"
              :item-value="
                (item) => ({
                  classroomName: item.classroomName,
                  classroomId: item.classroomId,
                  classroomLevel: item.classroomLevel,
                  classroomType: item.classroomType,
                })
              "
              v-model="classroom"
              :item-text="
                (item) =>
                  `${item.classroomName} ${
                    item.state === 'Archived'
                      ? '| Archived'
                      : item.state === 'Deleted'
                      ? '| Deleted'
                      : ''
                  } | ${$_t(`attribute.${item.classroomLevel}`)} | ${
                    item.teacherName
                  }`
              "
              outlined
              @input="bindClass($event)"
              :label="$_t('attribute.classes')"
            />
          </v-col>
          <v-col cols="12" class="mb-5">
            <v-select
              hide-details
              :items="allContracts"
              dense
              :disabled="contractDisabled"
              item-value="refID"
              v-model="contract"
              :item-text="
                (item) =>
                  `${item.refID} | ${$_t(`attribute.${item.contrcatType}`)} | ${
                    item.total
                  }`
              "
              outlined
              :label="$_t('attribute.contracts')"
            />
          </v-col>

          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
          <v-row v-if="guide" class="py-4 px-4 justify-space-between">
            <v-col cols="4">
              {{ `${$_t("attribute.costPerHour")}: ${guide.costPerHour ?? 0}` }}
            </v-col>
            <v-col cols="4">
              {{
                `${$_t("attribute.shouldCharge")}: ${guide.shouldCharge ?? 0} H`
              }}
            </v-col>
            <v-col cols="4">
              {{ `${$_t("attribute.shouldPay")}: ${guide.shouldPay ?? 0}` }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ deposit ? $_t("attribute.edit") : $_t("attribute.add") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ADD_DEPOSIT } from "@/packages/finance/schema/ADD_DEPOSIT.schema";
import message from "@/tools/Message";
export default {
  name: "AddDeposit",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    user: {
      required: true,
    },
    deposit: {
      default: null,
    },
    singleClass: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      schema: ADD_DEPOSIT.schema,
      form: ADD_DEPOSIT.model,
      classroom: null,
      disabled: false,
      guide: null,
      contract: null,
      contractDisabled: true,
    };
  },
  watch: {
    isShowModal(val) {
      if (val) {
        console.log(this.deposit);
        console.log(this.singleClass);
        if (this.singleClass) {
          this.disabled = true;
          this.classroom = {
            classroomId: this.singleClass.classroomId,
            classroomType: this.singleClass.classroomType,
            classroomName: this.singleClass.classroomName,
            classroomLevel: this.singleClass.classroomLevel,
          };
          this.bindClass({
            classroomId: this.singleClass.classroomId,
            classroomType: this.singleClass.classroomType,
            classroomName: this.singleClass.classroomName,
          });
        } else {
          this.contract = null;
          this.contractDisabled = true;
        }
        if (this.deposit) {
          this.form.depositType = this.deposit.depositType;
          this.contract = this.deposit.contractRefID;
          this.classroom = this.classes.find(
            (c) => c.classroomId === this.deposit.classroomId
          );
          this.form.classroomId = this.deposit.classroomId;
          this.form.classroomName = this.deposit.classroomName;
          this.form.classroomType = this.deposit.classroomType;
          this.form.student = this.deposit.studentFullName;
          this.form.amount = this.deposit.amount;
          this.form.submitMoment = this.deposit.submitMoment;
          this.form.paymentMethod = this.deposit.paymentMethod;
          this.form.description = this.deposit.description;
        } else {
          this.form.student =
            this.user?.fullName || this.user.studentFullName || null;
        }
      } else this.clear();
    },
    async classroom(val) {
      if (val) {
        // if (this.form.depositType !== "Classroom") return;
        this.guide = await this.$actions.getGuideForCreateDeposit({
          studentPublicKey:
            this.user?.publicKey ||
            this.user?.studentPublicKey ||
            this.deposit?.publicKey ||
            this.deposit?.studentPublicKey,
          classroomId: val.classroomId,
          depositType: this.form.depositType,
        });
      }
    },
    async "form.depositType"(value) {
      if (this.classroom) {
        this.guide = await this.$actions.getGuideForCreateDeposit({
          studentPublicKey:
            this.user?.publicKey ||
            this.user?.studentPublicKey ||
            this.deposit?.publicKey ||
            this.deposit?.studentPublicKey,
          classroomId: this.classroom.classroomId,
          depositType: value,
        });
      }

      // if want to edit in class  detail finance
      if (this.singleClass) return;
      // this.classroom = null;
      if (value === "Classroom" || value === "ClassroomSideCosts") {
        this.disabled = false;
        this.contract = null;
        this.contractDisabled = true;
      } else {
        this.classroom = null;
        this.disabled = true;
        this.contractDisabled = false;
      }
    },
  },
  validations: {
    ...ADD_DEPOSIT.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      classes: "class/classrooms",
      allContracts: "contract/contracts",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        if (!this.classroom && this.form.depositType === "Classroom")
          return message.error(this.$_t("selectClass"));
        if (!this.classroom && this.form.depositType === "ClassroomSideCosts")
          return message.error(this.$_t("selectClass"));
        if (!this.contract && this.form.depositType === "Contract")
          return message.error(this.$_t("selectContract"));
        let res = null;
        if (this.deposit) {
          res = await this.$actions.editDeposit({
            contractRefID:
              this.form.depositType === "Contract" ? this.contract : null,
            depositId: this.deposit.depositId,
            depositType: this.form.depositType,
            submitMoment: this.form.submitMoment,
            amount: parseFloat(this.form.amount),
            paymentMethod: this.form.paymentMethod,
            description: this.form.description,
            publicKey: this.deposit.publicKey,
            classroomId: ["Classroom", "ClassroomSideCosts"].includes(
              this.form.depositType
            )
              ? this.form.classroomId
              : null,
            classroomName: ["Classroom", "ClassroomSideCosts"].includes(
              this.form.depositType
            )
              ? this.form.classroomName
              : null,
            classroomType: ["Classroom", "ClassroomSideCosts"].includes(
              this.form.depositType
            )
              ? this.form.classroomType
              : null,
          });
        } else {
          res = await this.$actions.crateDeposit({
            contractRefID:
              this.form.depositType === "Contract" ? this.contract : null,
            submitMoment: this.form.submitMoment,
            depositType: this.form.depositType,
            amount: parseFloat(this.form.amount),
            paymentMethod: this.form.paymentMethod,
            description: this.form.description,
            publicKey: this.user.publicKey || this.user.studentPublicKey,
            classroomId: ["Classroom", "ClassroomSideCosts"].includes(
              this.form.depositType
            )
              ? this.form.classroomId
              : null,
            classroomName: ["Classroom", "ClassroomSideCosts"].includes(
              this.form.depositType
            )
              ? this.form.classroomName
              : null,
            classroomType: ["Classroom", "ClassroomSideCosts"].includes(
              this.form.depositType
            )
              ? this.form.classroomType
              : null,
          });
        }

        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
    clear() {
      this.form.depositType = "Classroom";
      this.form.submitMoment = null;
      this.form.amount = null;
      this.form.publicKey = null;
      this.form.classroomId = null;
      this.form.classroomName = null;
      this.form.classroomType = null;
      this.form.paymentMethod = null;
      this.form.description = null;

      this.classroom = null;
      this.contract = null;
      this.guide = null;
      this.disabled = false;
      this.contractDisabled = false;
      this.$v.$reset();
    },
    bindClass(val) {
      this.form.classroomId = val.classroomId;
      this.form.classroomName = val.classroomName;
      this.form.classroomType = val.classroomType;
    },
  },
};
</script>
