import { depositTypes } from "@/packages/finance/schema/DEPOSIT_TYPES.schema";
import { paymentTypes } from "@/packages/finance/schema/PAYMENTS_TYPES.schema";
const { required, decimal } = require("vuelidate/lib/validators");

export const ADD_DEPOSIT = {
  /* Form SCHEMA */
  schema: [
    {
      id: "depositType",
      label: "depositType",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      text: "text",
      value: "value",
      items: [...depositTypes],
      show: true,
    },
    {
      id: "paymentMethod",
      label: "paymentMethod",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      text: "text",
      value: "value",
      items: [...paymentTypes],
      show: true,
    },
    {
      id: "submitMoment",
      label: "submitMoment",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "datetime",
    },
    {
      id: "amount",
      label: "amount",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "student",
      label: "student",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      show: true,
      readonly: true,
      type: "text",
    },
    {
      id: "description",
      label: "description",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    submitMoment: null,
    amount: null,
    student: null,
    classroomId: null,
    classroomName: null,
    classroomType: null,
    paymentMethod: null,
    depositType: "Classroom",
    description: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      depositType: {
        required,
      },
      submitMoment: {
        required,
      },
      amount: {
        required,
        decimal,
      },
      student: { required },
      paymentMethod: { required },
      description: {},
    },
  },
};
